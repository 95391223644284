export default {
    home: {
        "what_we_do": "What We Do",
        "how_it_works": "How It Works",
        "why_hype": "Why Hype?",
        "our_brands": "Our Brands",
        "our_influencers": "Our Influencers",
        "contact_us": "Contact Us",

        "banner_text1": "Be more than an <br>influencer",
        "banner_text2": "A platform connecting brands with microinfluencers in Asia",
        "for_brands": "For <br>Brands",
        "for_brands_text": "Amplify your brand: Work with local influencers to drive business success in Asia.",
        "for_brands_text_1": "Influencer Scouting",
        "for_brands_text_2": "Influencer Relationship Management",
        "for_brands_text_3": "Campaign Set-up and Management",
        "for_brands_text_4": "Content Management",
        "for_brands_text_5": "Performance Analytics and Reporting",
        "for_influencers": "For <br>Influencers",
        "for_influencers_text": "Amplify your social influence and monetise your content by collaborating with brands.",
        "for_influencers_text_1": "Brand Collaborations",
        "for_influencers_text_2": "Partnership Management",
        "for_influencers_text_3": "Creative Resources",
        "for_influencers_text_4": "Networking Opportunities",
        "for_influencers_text_5": "Performance Analytics",

        "for_influencer_step_list": [
            {icon:'./assets/images/icon/user.png', title:'Register as a Microinfluencer', content:['Business or Creator Instagram account required', 'More than 3,000 Instagram followers']},
            {icon:'./assets/images/icon/search.png', title:'Browse Missions', content:['Apply for Missions with 1 click', 'Brand(s) review your Instagram profile']},
            {icon:'./assets/images/icon/electricity.png', title:'Complete Mission', content:['Follow the mission details to complete mission', 'Hype reviews if requirements are met']},
            {icon:'./assets/images/icon/money.png', title:'Earn monetary rewards', content:['Receive money in your Hype Wallet', 'Withdraw to your bank account']},
        ],

        "why_hype_list_1": [
            { title:'Find the Right Influencers', content:'Search our large portal of microinfluencers in Asia and tap into their social influence.' },
            { title:'Measure ROI Effectively', content:'Use our dashboard and analytics reports to evaluate the success of your campaigns.' },
            { title:'Humanise Your Marketing', content:'Consumers are more likely to trust recommendations made by people they know.' },
            { title:'Flexible Pricing Plans', content:'Choose a flexible and competitive pricing plan that fits your business needs.' },
            { title:'Localising Content', content:'Leverage local influencers’ to shape and elevate your content strategy.' },
        ],
        "why_hype_list_2": [
            { title:'Trusted Brand Partners', content:'We protect your personal branding by partnering with trusted brands only.' },
            { title:'Limitless Opportunities', content:'Collaborate with emerging and recognised brands to expand your reach.' },
            { title:'You Take the Lead', content:'Pick the brand you want to work with on our platform.' },
            { title:'Turn Your Passion into Profit', content:'Share what you love with your followers and get rewarded.' },
            { title:'Network with Other Influencers', content:'Grow your professional network and open doors to new collaboration opportunities.' },
            { title:'Your Storefront', content:'Create your own Hype storefront to promote products and earn commission.', subTitle:'(coming soon)' },
        ],

        "influencer_list": [
            {img:'./assets/images/home/home_7_1.jpg', viewImg:'./assets/images/home/home_7_1_2.jpg', name:'Yu Cheng', city:'Hong Kong', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'aryuuu.c', content2:'Health & Wellness, Sports, Lifestyle'},
            {img:'./assets/images/home/home_7_2.jpg', viewImg:'./assets/images/home/home_7_2_2.jpg', name:'Amara', city:'Hong Kong', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'lcsze317', content2:'Health & Wellness, Beauty, Lifestyle'},
            {img:'./assets/images/home/home_7_3.jpg', viewImg:'./assets/images/home/home_7_3_2.jpg', name:'Tanya', city:'Hong Kong', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'tanya10ya', content2:'Health & Wellness, Sports, Lifestyle'},
            {img:'./assets/images/home/home_7_4.jpg', viewImg:'./assets/images/home/home_7_4_2.jpg', name:'Ting Yin', city:'Hong Kong', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'tingyinnnnn', content2:'Beauty, Personal Care, Food & Beverage'},
            {img:'./assets/images/home/home_7_5.jpg', viewImg:'./assets/images/home/home_7_5_2.jpg', name:'Kannas', city:'Hong Kong', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'wuyanching', content2:'Health & Wellness, Beauty, Lifestyle'},
            {img:'./assets/images/home/home_7_6.jpg', viewImg:'./assets/images/home/home_7_6_2.jpg', name:'Birdie', city:'Hong Kong', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'tttyyymm', content2:'Food & Beverage, Lifestyle'},
            {img:'./assets/images/home/home_7_7.jpg', viewImg:'./assets/images/home/home_7_7_2.jpg', name:'Hei Hei', city:'Hong Kong', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'pangwhei', content2:'Gaming, Gadgets, Personal Care'},
            {img:'./assets/images/home/home_7_8.jpg', viewImg:'./assets/images/home/home_7_8_2.jpg', name:'Juliana', city:'Hong Kong', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'oppserve', content2:'Beauty, Sports, Lifestyle'},
        ],


        "why_influencer_marketing": "Why Influencer Marketing?",
        "why_influencer_marketing_text_1": "Increase Brand Awareness",
        "why_influencer_marketing_text_2": "Expand your reach and share brand messages to a wider audience through influencers’ network.",
        "why_influencer_marketing_text_3": "Drive Sales",
        "why_influencer_marketing_text_4": "Influence purchase decisions by fostering authentic connections with influencers’ engaged audience.",
        "why_influencer_marketing_text_5": "Build UGC",
        "why_influencer_marketing_text_6": "Enhance your content strategy by repurposing influencers’ User-Generated Content (UGC) across different marketing channels.",

        "how_it_works_text_1": "We help brands expand their presence in the Asia market by connecting them with our diverse, talented pool of influencers.",
        "how_it_works_text_2": "Find influencers to create sponsored Instagram content, attend publicity events, or host a livestream. Simply post a task on Hype, or what we call “Mission”, for talented microinfluencers to sign up and collaborate with your brand.",
        "how_it_works_text_3": "With our user-friendly platform, your brand can filter and choose the microinfluencers that best align with your brand and campaign objectives.",
        "how_it_works_text_4": "Create impactful influencer marketing campaigns with Hype.",
        "how_it_works_text_5": "Pick the brands you want to work with.",
        "how_it_works_text_6": 'Explore jobs or "Missions" on our platform listed by brands, such as creating Instagram posts, reels, stories, attending publicity events, or livestreaming.',
        "how_it_works_text_7": "Content creation (Instagram posts, stories, reels)",
        "how_it_works_text_8": "PR Events",
        "how_it_works_text_9": "Livestreaming",
        "how_it_works_text_10": "Marketplace (coming soon)",

        "our_brands_text_1": "Trusted by Global Brands",
        "our_brands_text_2": "Brand Spotlight",
        "our_brands_text_3": "Fentimans",
        "our_brands_text_4": "Fentimans is a botanical beverage brand from the UK with over 100 years of history of botanical brewing. Its wide selection of tonic water and soda water are botanically brewed with the finest ingredients and have won the recognition of many in the Food & Beverage industry.",

        "ready_to_amplify_your_brand": "Ready to amplify your brand?",
        "talk_with_our_business_team": "Talk with our Business Team",
        "ready_to_join_our_platform": "Ready to join our platform?",
        "request_more_information": "Request more Information",
        "request_proposal": "Request proposal",
        "your_email_address": "Your email address",

        "contact_us_text_1": "Hype is an",
        "contact_us_text_2": "eCargo",
        "contact_us_text_3": "company.",
        "contact_us_text_4": "Hong Kong Office",
        // "contact_us_text_5": "Room 13103 N, ATL Logistics Centre B,<br/>Berth 3, Kwai Chung Container Terminals<br/>Hong Kong",
        "contact_us_text_5": "Unit 02, 8/F, Inter-Continental Plaza,<br/>94 Granville Road, Tsim Sha Tsui, Kowloon,<br/>Hong Kong",
        "contact_us_text_6": "Social media",
    }
};
